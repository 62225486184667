<template>
	<div class="add-role-container">
		<el-form :model="formData" :rules="formRules" ref="formData">
			<el-form-item prop="role_name" label="角色名称" class="add-name-item">
				<el-input v-model="formData.role_name" placeholder="请输入" />
			</el-form-item>
		</el-form>
		<div class="check-all">
			权限：
			<el-checkbox v-model="checkedAll" @change="checkAll">全选</el-checkbox>
		</div>
		<div class="role-box">
			<div class="role-content" v-for="(item,i) in data" :key="i">
				<div class="f-item">
					<el-checkbox @change="changeFData(i)" :indeterminate="item.isIndeterminate" v-model="item.checkAll"
						:label="item.fName"></el-checkbox>
				</div>
				<div class="s-item">
					<el-checkbox-group v-model="item.checkList">
						<div v-for="(item2,j) in item.sData" :key="j">
							<el-checkbox @change="changeSData" :label="item2.sKey">{{item2.sName}}</el-checkbox>
						</div>
					</el-checkbox-group>
				</div>
			</div>
		</div>
		<div class="bottom-button">
			<el-button class="commit" @click="commit">提 交</el-button>
			<el-button @click="back">返 回</el-button>
			<el-button @click="reset">重 置</el-button>
		</div>
	</div>
</template>

<script>
	import {
		permissionList,
		addRoles,
		detail,
		update
	} from '../../api/role.js'
	export default {
		data() {
			return {
				formData: {},
				formRules: {
					role_name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'change'
					}]
				},
				checkedAll: false,
				data: [],
				checkList: []
			}
		},
		watch: {
			'data': {
				handler(newVal) {
					this.formData.permission_list = []
					newVal.forEach(e => {
						e.checkList.forEach((item) => {
							this.formData.permission_list.push(item)
						})
					})
				},
				immediate: true,
				deep: true
			}
		},
		methods: {
			async getData() {
				if (this.$route.query.role_id) {
					this.formData = (await detail(this.$route.query.role_id)).data
				}
				let res = (await permissionList()).data
				console.log(res)
				for (let key in res) {
					console.log(key)
					let obj = {
						fName: key,
						isIndeterminate: false,
						checkList: [],
						checkAll: false
					}
					let obj2 = []
					for (let key2 in res[key]) {
						obj2.push({
							sName: res[key][key2],
							sKey: key2
						})
					}
					obj.sData = obj2
					this.data.push(obj)
				}
				this.data.checkAll = false
				console.log(this.data)
				console.log(this.formData)
				for (let k in this.formData.permission) {
					console.log(k)
					this.data.forEach((e,i) => {
						if (k == e.fName) {
							let preKeys = Object.keys(this.formData.permission[k])
							this.data[i].checkList = preKeys
						}
					})
				}
				this.changeCheckShow()
			},
			changeCheckShow() {
				this.data.forEach((e, i) => {
					if (e.checkList.length == e.sData.length) {
						this.data[i].checkAll = true
						this.data[i].isIndeterminate = false
					} else if (e.checkList.length > 0) {
						this.data[i].isIndeterminate = true
						this.data[i].checkAll = false
					} else {
						this.data[i].isIndeterminate = false
						this.data[i].checkAll = false
					}
				})
				let isCheckAll = true
				this.data.forEach(e => {
					if (!e.checkAll) {
						isCheckAll = false
					}
				})
				this.checkedAll = isCheckAll
			},
			changeSData() {
				this.changeCheckShow()
			},
			changeFData(i) {
				if (this.data[i].checkAll) {
					this.data[i].checkList = this.data[i].sData.map(e => {
						return e.sKey
					})
				} else {
					this.data[i].checkList = []
				}
				this.data[i].isIndeterminate = false
				let isCheckAll = true
				this.data.forEach(e => {
					if (!e.checkAll) {
						isCheckAll = false
					}
				})
				this.checkedAll = isCheckAll
			},
			checkAll() {
				if (this.checkedAll) {
					this.data.forEach((e, i) => {
						this.data[i].checkAll = true
						this.changeFData(i)
					})
				} else {
					this.data.forEach((e, i) => {
						this.data[i].checkAll = false
						this.changeFData(i)
					})
				}
			},
			commit() {
				this.$refs["formData"].validate(async (valid) => {
					if (valid) {
						console.log(this.formData)
						this.formData.permission = this.formData.permission_list.join(',')
						if(this.formData.role_id){
							await update(this.formData)
							this.$message.success('更新成功')
						}else{
							await addRoles(this.formData)
							this.$message.success('新增成功')
							this.reset()
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			reset() {
				this.formData = {}
				this.data = this.data.map(e => {
					e.isIndeterminate = false
					e.checkAll = false
					e.checkList = []
					return e
				})
				this.checkedAll = false
			},
			back() {
				this.$router.push('role')
			}
		},
		created() {
			this.getData()
		}
	}
</script>

<style lang="scss">
	.add-role-container {
		background-color: #ffffff;
		padding: 2.1rem 1.7rem 3.4rem 1.7rem;
		display: flex;
		flex-direction: column;
		height: 100%;
		box-sizing: border-box;

		.add-name-item {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			.el-input {
				width: 380px;
				margin-top: 8px;
			}
		}

		.check-all {
			display: flex;
			font-size: 14px;
			color: #666666;

			.el-checkbox {
				margin-left: 4px;
			}
		}

		.role-box {
			border: 1px solid #DDDDDD;
			margin-top: 10px;
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow-y: scroll;
		}

		.role-content {
			display: flex;
			border-bottom: 1px solid #DDDDDD;

			.el-checkbox__label {
				color: #111111;
				font-size: 14px;
			}

			.f-item {
				display: flex;
				width: 358px;
				align-items: center;
				border-right: 1px solid #DDDDDD;
				padding-left: 111px;
				box-sizing: border-box;
			}

			.s-item {
				padding: 27px 42px;
				flex: 1;

				&>.el-checkbox-group {
					display: grid;
					grid-template-columns: 2fr 3fr;
					grid-row-gap: 29px;
				}
			}
		}

		.commit {
			color: #FFFFFF;
			background-color: #3B91FF;
		}

		.bottom-button {
			margin-top: 45px;
			display: flex;
			justify-content: center;
		}
	}
</style>
